/* eslint-disable @next/next/no-img-element */
import React from "react";

import Login from "./auth/login";
import Head from "next/head";

interface AppRootProps {
  session: any;
}

export default function AppRoot({ session }: AppRootProps) {
  return (
    <>
      <Head>
        <title>ZigOps Payroll</title>
        <link rel="canonical" href="https://www.zigops-material.com" />
        <meta name="description" content="ZigOps Payroll" />
        <meta
          name="keywords"
          content="ZigOps Payroll"
        />
      </Head>
      <Login />
    </>
  );
}
